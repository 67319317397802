@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import "./Themes.css";
@import "./utils.css";

body,
html,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Lato", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 55px;
  margin-bottom: 0px;
}
h2 {
  font-size: 24px;
  margin-bottom: 5px;
  margin-top: 5px;
}
h3 {
  font-size: 18px;
  color: gray;
  margin-bottom: 20px;
  margin-top: 5px;
}
a {
  color: #3579bd;
}

.App {
  text-align: center;
  min-height: 100vh;
}

.app-header-left {
  display: flex;
  margin: 1.25rem;
}
.app-header-right {
  display: flex;
  justify-content: flex-end;
  margin: 1.25rem;
}

.draft-text {
  color: rgb(190, 62, 62);
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(190, 62, 62);
  font-weight: bold;
  padding: 0.313rem;
  font-size: 1.25rem;
}
.preview-text {
  color: rgb(62, 81, 190);
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(62, 81, 190);
  font-weight: bold;
  padding: 0.25rem;
  font-size: 1.125rem;
}

@media (max-width: 599px) {
  .app-header-left,
  .app-header-right {
    margin: 0.5rem;
  }
  .preview-text,
  .draft-text {
    border-radius: 8px;
    font-size: 1rem;
  }
}

.logo-navbar {
  height: 50px;
  max-width: 100%;
  object-fit: contain;
  vertical-align: middle;
}
.phonic-logo-navbar {
  width: 100%;
  max-width: 100px;
  vertical-align: top;
  opacity: 0.5;
}
.logo-welcome {
  height: 120px;
  max-width: 100%;
  margin-top: 20px;
  object-fit: contain;
}
.logo-mic {
  height: 120px;
  width: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.Help {
  min-height: 100vh;
  background-color: #f9f9f9;
}

.Loading {
  background: #fafafa;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.help-img {
  width: 100%;
  margin: 20px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #2d4b65 !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #2d4b65 !important;
}

.dark-highlight {
  color: #2d4b65;
}

.sound-wave {
  display: None;
}

.waveform-inner-container {
  float: right;
  width: 90%;
  height: 100%;
}

.play-button-container {
  float: left;
  height: 100%;
}
.play-pause-span {
  font-size: 24px;
}
.play-button-container:hover {
  color: rgba(255, 255, 255, 0.25) !important;
  cursor: pointer;
}

.audio-container {
  margin-bottom: 10px;
}

.learn-more {
  color: #f9f9f9;
}

.sweet-loading {
  margin-bottom: -5px;
}

.record-time-text {
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 4px;
  width: 60px;
  background: rgba(255, 255, 255, 0.4);
  color: black;
  border-radius: 5px;
  border: 2px solid rgba(200, 200, 200, 0.6);
}

.selection-paper {
  width: 100%;
  float: left;
  margin: 5px 0px;
  padding: 4px;
  padding-left: 12px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.7) !important;
  box-shadow: none !important;
}

@media (max-width: 599px) {
  .selection-paper {
    margin: 2px 0px;
    border-radius: 8px;
  }
}

.selection-paper.selected {
  background: white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.selection-paper .MuiFormControlLabel-root {
  width: 100% !important;
  text-align: left !important;
}

.likert-paper {
  overflow-x: scroll;
  margin: 12px auto;
}
.likert-paper table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  text-indent: initial;
}
.likert-paper th {
  min-width: 80px;
  font-weight: inherit;
  position: relative;
  text-align: center;
}
.likert-paper tbody > tr > th:first-child {
  min-width: 100px;
  margin-left: 10px !important;
  position: sticky;
  left: 0px !important;
  background-color: white;
  z-index: 10;
  text-align: left;
  vertical-align: middle;
}
.likert-paper td,
.likert-paper th {
  left: 0px !important;
  padding: 8px;
  border-bottom: solid rgb(233, 233, 233) 1px;
}
.likert-hr {
  height: 1px;
  background-color: #ccc;
  border: none;
  width: 100%;
  margin: 4;
}

.audio-toggle-mode {
  font-size: 18px !important;
  margin-bottom: 24px !important;
  background: none;
  border: none;
}
.audio-toggle-mode:hover {
  text-decoration: underline;
  cursor: pointer;
}

.consent-iframe-container {
  height: 60vh;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin: 14px !important;
}
.consent-iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.Home {
  padding: 16px;
  height: 100vh;
  background-color: rgb(1, 192, 143);
}
.Home button {
  background-color: rgb(1, 192, 143) !important;
}

.home-paper {
  padding: 16px;
}

.video-overlay-button {
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  text-align: center;
}

.camera-on-button {
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 100%;
  text-align: center;
}

.another-video-button {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.video-helper-text {
  font-size: 12px;
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 8px;
}

.demographics-item {
  padding: 10px;
  width: 180px;
}

/* File Upload */
.files input {
  z-index: 3;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  min-height: 200px;
  width: 100%;
  margin: 0 auto;
  font-family: Inter, sans-serif;
}

.form-control::-webkit-file-upload-button {
  display: none;
}

.form-group {
  position: relative;
}

.form-group .upload-button {
  position: absolute;
  z-index: 5;
  color: white;
  border: none;
  background: #3a71dc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  border-radius: 12px;

  margin: 12px auto;
  width: 160px;
  margin-left: -80px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  text-align: center;
  vertical-align: middle;
}

.form-group .upload-button:hover {
  box-shadow: none;
}

.file-upload-img-preview {
  left: 12px;
  top: 12px;
  position: absolute;
  z-index: 0;
  max-width: min(280px, 80%);
  max-height: 200px;
}

.chevron-right {
  margin-left: 10px;
}
.chevron-left {
  margin-right: 10px;
}

/* This removes the "No file chosen" text */
input[type="file"] {
  color: transparent;
  display: flex;
  justify-content: center;
}

.time-allowed {
  margin-top: 12px;
  margin-bottom: 8px;
  padding: 0.5rem 1rem;
  color: #323434;
  font-weight: 700;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: rgb(54 91 155 / 10%) 0px 0px 35px, rgb(0 0 0 / 7%) 0px 0px 15px;
}

.ripple-base {
  transition: background 500ms;
}

.stopped-recording {
  background-color: rgba(255, 255, 255, 1) !important;
  transition: background 0s;
}

.stopped-recording2 {
  color: #dc493a;
}

.date-wrapper {
  padding: 15px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  width: 100%;
}

.date-wrapper > * {
  margin: 5px;
  padding: 15px 0;
}

.generic-error {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
}

.main {
  flex: 1 1 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.bottom-spacer {
  padding-top: 32px;
}

/* Overrides */
.video-recorder__Wrapper-sc-7k20rv-0 {
  overflow: visible !important;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

video {
  border-radius: 6px;
}

.switch-camera-view__SVGWrapper-sc-13l3hbu-0 {
  padding-left: 0 !important;
  text-align: center;
}

.question-outer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8;
}

.ranking-paper-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  margin: 8px 0px;
  padding: 6px 6px 6px 16px;
  position: relative;
  background: rgba(251, 251, 251, 0.7);
  box-shadow: none !important;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.7) !important;
}

.ranking-grab-icon {
  margin-right: 4%;
  transform: scale(1);
}

.ranking-dropdown {
  transform: scale(0.85);
}

.option-text {
  width: 80%;
  padding: 0 6px;
  margin: 0;
  text-align: left;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
}

.not-found-container {
  height: 100vh;
  width: 100%;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgb(209, 209, 209) 100%
  );
}
.not-found {
  height: 100vh;
  display: flex;
  padding: 0 10%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
}

.not-found h2 {
  color: #2f2e41d6;
}
.not-found {
  color: rgba(104, 104, 104, 0.839);
}

.not-found img {
  width: 60%;
}

.survey-alert {
  width: 50%;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
}

.button-bar {
  margin-top: 20px;
}

.screen-wrapper {
  max-width: 600px;
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 300px;
}

@media (max-width: 599px) {
  .selection svg {
    width: 18px;
    height: 18px;
  }
}

.message-wrapper {
  padding: 0 24px;
  color: #333333;
  line-height: 1.8;
}

.microphone-check-text {
  white-space: nowrap;
  position: relative;
  height: 100%;
  transition: 0.3s all ease;
}
