@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Encode+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Encode+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@font-face {
  font-family: "MarsCentra";
  src: url(/static/media/MarsCentra-Bold.3ad592fb.eot);
  src: url(/static/media/MarsCentra-Book.afa93aaf.eot);
  src: url(/static/media/MarsCentra-Book.cdae4a1a.woff) format("woff"),
    url(/static/media/MarsCentra-Book.9f2a4cc3.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MarsCentra";
  src: url(/static/media/MarsCentra-Bold.3ad592fb.eot);
  src: url(/static/media/MarsCentra-Bold.d7c03359.woff) format("woff"),
    url(/static/media/MarsCentra-Bold.670ec3eb.svg) format("svg");
  font-weight: bold;
  font-style: normal;
}

.App {
  background-size: cover;
}
.title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: black;
  margin: 0;
}

.subtitle {
  font-family: "Roboto", sans-serif;
  color: #515455;
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}

.footer {
  color: #515455;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 38px;
  text-align: left;
  margin: 0 12px;
}

.stimuli {
  margin: 12px 12px;
}
.stimuli-container {
  width: 100%;
}
.rounded {
  border-radius: 6px;
}

.welcome {
  font-family: "Encode Sans", sans-serif;
}
.main-button {
  background-color: #63878e;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.main-button.empty-skip {
  margin-right: 40px;
}
.main-button.empty-prev {
  margin-left: 40px;
}
.main-button.Mui-disabled {
  background-color: #63878e5e !important;
  box-shadow: none;
}

.previous-button {
  color: #63878e;
}

.progress-bar .MuiLinearProgress-bar {
  background-color: #63878e;
}

/* SERIF */
.serif .welcome {
  font-family: "Georgia", "Times New Roman";
}
.serif .title {
  font-family: "Georgia", "Times New Roman" !important;
}
.serif .subtitle {
  font-family: "Lato", sans-serif;
  color: gray;
}

/* OPEN SANS */
.opensans .title {
  font-family: "Open Sans", "Times New Roman" !important;
}
.opensans .subtitle {
  font-family: "Lato", sans-serif;
  color: gray;
}

/* Mulish */
.mulish .welcome {
  font-family: "Mulish", "Times New Roman";
}
.mulish .title {
  font-family: "Mulish", "Times New Roman" !important;
}

.mulish .subtitle {
  font-family: "Lato", sans-serif;
  color: gray;
}

/* TANGERINE */
.tangerine.App {
  color: white;
  background: linear-gradient(121.94deg, #f6d365 0.42%, #fda085 100%);
}
.tangerine .title {
  color: #f9f9f9;
}
.tangerine .subtitle {
  color: #c57b57;
}
.tangerine .main-button {
  color: #fda085 !important;
  background-color: #f9f9f9 !important;
}
.tangerine .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.tangerine .previous-button {
  color: #c57b57 !important;
}
.tangerine .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(252, 96, 118, 0.5) 0%,
    rgba(255, 154, 68, 0.5) 100%
  );
}

/* LAVENDAR */
.lavender.App {
  background: linear-gradient(121.94deg, #a18cd1 0.42%, #fbc2eb 100%);
}
.lavender .title {
  color: #f9f9f9;
}
.lavender .subtitle {
  color: #856084;
}
.lavender .main-button {
  color: #a18cd1 !important;
  background-color: #f9f9f9 !important;
}
.lavender .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.lavender .previous-button {
  color: #856084 !important;
}
.lavender .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(196, 113, 245, 0.45) 0%,
    rgba(250, 113, 205, 0.45) 100%
  );
}

/* OCEAN */
.ocean.App {
  background: linear-gradient(121.94deg, #a1c4fd 0.42%, #c2e9fb 100%);
}
.ocean .title {
  color: #f9f9f9;
}
.ocean .subtitle {
  color: #718abf;
}
.ocean .main-button {
  color: #a1c4fd !important;
  background-color: #f9f9f9 !important;
}
.ocean .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.ocean .previous-button {
  color: #718abf !important;
}
.ocean .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(111, 134, 214, 0.5) 0%,
    rgba(72, 198, 239, 0.5) 99.99%
  );
}

/* PHONIC MINT */
.mint.App {
  background: linear-gradient(121.94deg, #2ac79f 0.42%, #6ac2ac 100%);
}
.mint .title {
  color: #ffffff;
}
.mint .subtitle {
  color: #0b8062;
}
.mint .main-button {
  color: #2ac79f !important;
  background-color: #f9f9f9 !important;
}
.mint .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.mint .previous-button {
  color: #0e8365 !important;
}
.mint .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(7, 97, 74, 0.5) 0%,
    rgba(37, 175, 140, 0.5) 100%
  );
}

/* TURQUOISE */
.turquoise.App {
  background: linear-gradient(121.94deg, #96deda 0.42%, #50c9c3 100%);
}
.turquoise .title {
  color: #ffffff;
}
.turquoise .subtitle {
  color: #1f8b86;
}
.turquoise .main-button {
  color: #50c9c3 !important;
  background-color: #f9f9f9 !important;
}
.turquoise .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.turquoise .previous-button {
  color: #1f8b86 !important;
}
.turquoise .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(72, 164, 159, 0.5) 0%,
    rgba(75, 203, 197, 0.5) 100%
  );
}

/* PASSIONFRUIT */
.passionfruit.App {
  background: linear-gradient(121.94deg, #ff758c 0.42%, #ff7eb3 100%);
}
.passionfruit .title {
  color: #ffffff;
}
.passionfruit .subtitle {
  color: #ca3271;
}
.passionfruit .main-button {
  color: #ff758c !important;
  background-color: #f9f9f9 !important;
}
.passionfruit .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.passionfruit .previous-button {
  color: #ca3271 !important;
}
.passionfruit .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(200, 60, 117, 0.5) 0%,
    rgba(223, 91, 113, 0.5) 100%
  );
}

/* MIDNIGHT */
.midnight.App {
  background: linear-gradient(121.94deg, #2b5876 0.42%, #4e4376 100%);
}
.midnight .title {
  color: #ffffff;
}
.midnight .subtitle {
  color: #cecece;
}
.midnight .main-button {
  color: #2b5876 !important;
  background-color: #f9f9f9 !important;
}
.midnight .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.midnight .previous-button {
  color: #cecece !important;
}
.midnight .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(42, 36, 62, 0.5) 0%,
    rgba(36, 80, 109, 0.5) 100%
  );
}

/* COFFEE */
.coffee.App {
  background: linear-gradient(121.94deg, #fdfcfb 0.42%, #e2d1c3 100%);
}
.coffee .title {
  color: #232529;
}
.coffee .subtitle {
  color: #878c8f;
}
.coffee .main-button {
  color: #ffffff !important;
  background-color: #a57650 !important;
}
.coffee .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.coffee .previous-button {
  color: #a57650 !important;
}
.coffee .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(165, 118, 80, 0.67) 0%,
    #dcccbc 100%
  );
}

/* LIME */
.lime.App {
  background: linear-gradient(121.94deg, #d2f484 0.42%, #96e6a1 100%);
}
.lime .title {
  color: #232529;
}
.lime .subtitle {
  color: #515455;
}
.lime .main-button {
  color: #96e6a1 !important;
  background-color: #232529 !important;
}
.lime .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.lime .previous-button {
  color: #515455 !important;
}
.lime .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(91, 196, 106, 0.5) 0%,
    rgba(191, 227, 109, 0.5) 100%
  );
}

/* LIGHT */
.light.App {
  background: linear-gradient(121.94deg, #ebedee 0.42%, #fdfbfb 100%);
}
.light .title {
  color: #232529;
}
.light .subtitle {
  color: #515455;
}
.light .main-button {
  color: #616569 !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #5a5a5a;
}
.light .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.light .previous-button {
  color: #5e6264 !important;
}
.light .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(270deg, rgba(35, 37, 41, 0.8) 0%, #c4c4c4 100%);
}

/* DARK */
.dark.App {
  background: linear-gradient(
    121.94deg,
    rgba(0, 0, 0, 0.9) 0.42%,
    #2e2e2e 100%
  );
}
.dark .title {
  color: #f9f9f9;
}
.dark .subtitle {
  color: #d1d1d1;
}

.dark .main-button {
  color: #8e8e8e !important;
  background-color: #f9f9f9 !important;
}
.dark .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.dark .previous-button {
  color: #d1d1d1 !important;
}
.dark .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(196, 196, 196, 0.4) 0%,
    #232529 100%
  );
}

/*  WHITE */
.white.App {
  background: white;
}
.white .title {
  color: black;
}
.white .subtitle {
  color: black;
}
.white .main-button {
  color: white !important;
  background-color: black !important;
}
.white .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.white .previous-button {
  color: black !important;
}
.white .progress-bar .MuiLinearProgress-bar {
  background: black;
}

/* MARS + REVOLT */
.mars.App {
  background: #00d7b8;
}

@media only screen and (min-width: 601px) {
  .mars.App {
    background-size: 100% auto;
    background-position: 50% 70%;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 600px) {
  .mars.App {
    background-size: 250% auto;
    background-position: 55% 100%;
    background-repeat: no-repeat;
  }
}

.mars .MuiFormLabel-root,
.mars .MuiInputBase-input,
.mars .MuiTypography-root {
  font-family: "MarsCentra" !important;
}

.mars .MuiPaper-root,
.mars .MuiInputBase-root,
.mars input {
  box-shadow: none !important;
  border-radius: 0;
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.mars .title {
  color: #040da4;
  font-family: "MarsCentra" !important;
  font-weight: bold;
}
.mars .subtitle,
.mars .footer {
  color: #040da4;
  font-family: "MarsCentra" !important;
  font-weight: normal;
}
.mars .main-button {
  color: #040da4 !important;
  background-color: white !important;
  transition: color 0.2s, background-color 0.2s;
  box-shadow: none;
  border-radius: 0 !important;
  font-family: "MarsCentra" !important;
}
.mars .main-button:hover {
  background-color: #040da4 !important;
  color: white !important;
}

.mars .next-button {
  color: white !important;
  background-color: #040da4 !important;
  transition: color 0.2s, background-color 0.2s !important;
  font-family: "MarsCentra" !important;
}
.mars .next-button:hover {
  background-color: #ffffff !important;
  color: #040da4 !important;
}
.mars .next-button:hover .chevron-right {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}
.mars .next-button:hover .chevron-right path {
  fill: #040da4;
}
.mars .next-button .chevron-right {
  margin: 0 0 0 10px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.mars .next-button .chevron-right path {
  fill: #ffffff;
  transition: fill 0.2s;
}
.mars .main-button.Mui-disabled {
  background-color: #f9f9f95e !important;
  box-shadow: none;
}
.mars .previous-button {
  background-color: transparent !important;
  border: 0 !important;
  color: #040da4 !important;
  opacity: 0.5 !important;
  transition: opacity 0.2s !important;
  font-family: "MarsCentra" !important;
}
.mars .previous-button:hover {
  opacity: 1 !important;
}
.mars .previous-button:hover .chevron-left {
  -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
}
.mars .previous-button .chevron-left {
  margin: 0 10px 0 0 !important;
  transition: -webkit-transform 0.2s !important;
  transition: transform 0.2s !important;
  transition: transform 0.2s, -webkit-transform 0.2s !important;
}
.mars .previous-button .chevron-left path {
  fill: #040da4 !important;
}
.mars .progress-bar .MuiLinearProgress-bar {
  background: white;
}
.mars .record-button {
  color: #040da4 !important;
  background: #ff8203 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  font-family: "MarsCentra" !important;
}
.mars .record-button:hover {
  background: #ff9122 !important;
  box-shadow: none;
  border-radius: 0 !important;
}
/* CUSTOM */
.dark.custom .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(151, 150, 150, 0.4) 0%,
    rgba(230, 227, 227, 0.4) 100%
  );
}
/* CUSTOM */
.light.custom .progress-bar .MuiLinearProgress-bar {
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(49, 49, 49, 0.4) 100%
  );
}

/* Survey Alignment Prop */
.left .title,
.left .subtitle,
.left .stimuli-container {
  text-align: left;
}
.right .title,
.right .subtitle,
.right .stimuli-container {
  text-align: right;
}

.App.left {
  text-align: left;
}

.App.right {
  text-align: right;
}

.App.left .main-button,
.App.right .main-button {
  margin-right: 0;
}

.survey-alert.left {
  margin-left: 0;
}

.survey-alert.right {
  margin-right: 0;
}


.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0;
}

.mr-auto {
  margin-right: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 8px;
}

.pt-1 {
  padding-top: 8px;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

body,
html,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Lato", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 55px;
  margin-bottom: 0px;
}
h2 {
  font-size: 24px;
  margin-bottom: 5px;
  margin-top: 5px;
}
h3 {
  font-size: 18px;
  color: gray;
  margin-bottom: 20px;
  margin-top: 5px;
}
a {
  color: #3579bd;
}

.App {
  text-align: center;
  min-height: 100vh;
}

.app-header-left {
  display: flex;
  margin: 1.25rem;
}
.app-header-right {
  display: flex;
  justify-content: flex-end;
  margin: 1.25rem;
}

.draft-text {
  color: rgb(190, 62, 62);
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(190, 62, 62);
  font-weight: bold;
  padding: 0.313rem;
  font-size: 1.25rem;
}
.preview-text {
  color: rgb(62, 81, 190);
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(62, 81, 190);
  font-weight: bold;
  padding: 0.25rem;
  font-size: 1.125rem;
}

@media (max-width: 599px) {
  .app-header-left,
  .app-header-right {
    margin: 0.5rem;
  }
  .preview-text,
  .draft-text {
    border-radius: 8px;
    font-size: 1rem;
  }
}

.logo-navbar {
  height: 50px;
  max-width: 100%;
  object-fit: contain;
  vertical-align: middle;
}
.phonic-logo-navbar {
  width: 100%;
  max-width: 100px;
  vertical-align: top;
  opacity: 0.5;
}
.logo-welcome {
  height: 120px;
  max-width: 100%;
  margin-top: 20px;
  object-fit: contain;
}
.logo-mic {
  height: 120px;
  width: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.Help {
  min-height: 100vh;
  background-color: #f9f9f9;
}

.Loading {
  background: #fafafa;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.help-img {
  width: 100%;
  margin: 20px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #2d4b65 !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #2d4b65 !important;
}

.dark-highlight {
  color: #2d4b65;
}

.sound-wave {
  display: None;
}

.waveform-inner-container {
  float: right;
  width: 90%;
  height: 100%;
}

.play-button-container {
  float: left;
  height: 100%;
}
.play-pause-span {
  font-size: 24px;
}
.play-button-container:hover {
  color: rgba(255, 255, 255, 0.25) !important;
  cursor: pointer;
}

.audio-container {
  margin-bottom: 10px;
}

.learn-more {
  color: #f9f9f9;
}

.sweet-loading {
  margin-bottom: -5px;
}

.record-time-text {
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 4px;
  width: 60px;
  background: rgba(255, 255, 255, 0.4);
  color: black;
  border-radius: 5px;
  border: 2px solid rgba(200, 200, 200, 0.6);
}

.selection-paper {
  width: 100%;
  float: left;
  margin: 5px 0px;
  padding: 4px;
  padding-left: 12px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.7) !important;
  box-shadow: none !important;
}

@media (max-width: 599px) {
  .selection-paper {
    margin: 2px 0px;
    border-radius: 8px;
  }
}

.selection-paper.selected {
  background: white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.selection-paper .MuiFormControlLabel-root {
  width: 100% !important;
  text-align: left !important;
}

.likert-paper {
  overflow-x: scroll;
  margin: 12px auto;
}
.likert-paper table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  text-indent: 0;
  text-indent: initial;
}
.likert-paper th {
  min-width: 80px;
  font-weight: inherit;
  position: relative;
  text-align: center;
}
.likert-paper tbody > tr > th:first-child {
  min-width: 100px;
  margin-left: 10px !important;
  position: sticky;
  left: 0px !important;
  background-color: white;
  z-index: 10;
  text-align: left;
  vertical-align: middle;
}
.likert-paper td,
.likert-paper th {
  left: 0px !important;
  padding: 8px;
  border-bottom: solid rgb(233, 233, 233) 1px;
}
.likert-hr {
  height: 1px;
  background-color: #ccc;
  border: none;
  width: 100%;
  margin: 4;
}

.audio-toggle-mode {
  font-size: 18px !important;
  margin-bottom: 24px !important;
  background: none;
  border: none;
}
.audio-toggle-mode:hover {
  text-decoration: underline;
  cursor: pointer;
}

.consent-iframe-container {
  height: 60vh;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin: 14px !important;
}
.consent-iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.Home {
  padding: 16px;
  height: 100vh;
  background-color: rgb(1, 192, 143);
}
.Home button {
  background-color: rgb(1, 192, 143) !important;
}

.home-paper {
  padding: 16px;
}

.video-overlay-button {
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  text-align: center;
}

.camera-on-button {
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 100%;
  text-align: center;
}

.another-video-button {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.video-helper-text {
  font-size: 12px;
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 8px;
}

.demographics-item {
  padding: 10px;
  width: 180px;
}

/* File Upload */
.files input {
  z-index: 3;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  min-height: 200px;
  width: 100%;
  margin: 0 auto;
  font-family: Inter, sans-serif;
}

.form-control::-webkit-file-upload-button {
  display: none;
}

.form-group {
  position: relative;
}

.form-group .upload-button {
  position: absolute;
  z-index: 5;
  color: white;
  border: none;
  background: #3a71dc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  border-radius: 12px;

  margin: 12px auto;
  width: 160px;
  margin-left: -80px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  text-align: center;
  vertical-align: middle;
}

.form-group .upload-button:hover {
  box-shadow: none;
}

.file-upload-img-preview {
  left: 12px;
  top: 12px;
  position: absolute;
  z-index: 0;
  max-width: min(280px, 80%);
  max-height: 200px;
}

.chevron-right {
  margin-left: 10px;
}
.chevron-left {
  margin-right: 10px;
}

/* This removes the "No file chosen" text */
input[type="file"] {
  color: transparent;
  display: flex;
  justify-content: center;
}

.time-allowed {
  margin-top: 12px;
  margin-bottom: 8px;
  padding: 0.5rem 1rem;
  color: #323434;
  font-weight: 700;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: rgb(54 91 155 / 10%) 0px 0px 35px, rgb(0 0 0 / 7%) 0px 0px 15px;
}

.ripple-base {
  transition: background 500ms;
}

.stopped-recording {
  background-color: rgba(255, 255, 255, 1) !important;
  transition: background 0s;
}

.stopped-recording2 {
  color: #dc493a;
}

.date-wrapper {
  padding: 15px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  width: 100%;
}

.date-wrapper > * {
  margin: 5px;
  padding: 15px 0;
}

.generic-error {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
}

.main {
  flex: 1 1 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.bottom-spacer {
  padding-top: 32px;
}

/* Overrides */
.video-recorder__Wrapper-sc-7k20rv-0 {
  overflow: visible !important;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

video {
  border-radius: 6px;
}

.switch-camera-view__SVGWrapper-sc-13l3hbu-0 {
  padding-left: 0 !important;
  text-align: center;
}

.question-outer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8;
}

.ranking-paper-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 8px 0px;
  padding: 6px 6px 6px 16px;
  position: relative;
  background: rgba(251, 251, 251, 0.7);
  box-shadow: none !important;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.7) !important;
}

.ranking-grab-icon {
  margin-right: 4%;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.ranking-dropdown {
  -webkit-transform: scale(0.85);
          transform: scale(0.85);
}

.option-text {
  width: 80%;
  padding: 0 6px;
  margin: 0;
  text-align: left;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
}

.not-found-container {
  height: 100vh;
  width: 100%;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgb(209, 209, 209) 100%
  );
}
.not-found {
  height: 100vh;
  display: flex;
  padding: 0 10%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
}

.not-found h2 {
  color: #2f2e41d6;
}
.not-found {
  color: rgba(104, 104, 104, 0.839);
}

.not-found img {
  width: 60%;
}

.survey-alert {
  width: 50%;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
}

.button-bar {
  margin-top: 20px;
}

.screen-wrapper {
  max-width: 600px;
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 300px;
}

@media (max-width: 599px) {
  .selection svg {
    width: 18px;
    height: 18px;
  }
}

.message-wrapper {
  padding: 0 24px;
  color: #333333;
  line-height: 1.8;
}

.microphone-check-text {
  white-space: nowrap;
  position: relative;
  height: 100%;
  transition: 0.3s all ease;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: green;
  fill: none;
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  border-radius: 50%;
  stroke-width: 2;
  stroke: green;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
          animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.large {
  width: 56px;
  height: 56px;
  display: block;
  margin: 10% auto;
}

.small {
  width: 20px;
  height: 20px;
  display: inline-block;
  stroke-width: 5;
}

.checkmark__check {
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
          animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
}
@-webkit-keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #fff;
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #fff;
  }
}

.status-icon {
  height: 16px;
}

.status-icon svg {
  width: 100px;
  display: block;
  margin: 40px auto 0;
}

.status-icon .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.status-icon .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.status-icon .path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.status-icon .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

.status-icon p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
}
.status-icon .success {
  color: #73af55;
}
.status-icon .error {
  color: #d06079;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

