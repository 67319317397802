
.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0;
}

.mr-auto {
  margin-right: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 8px;
}

.pt-1 {
  padding-top: 8px;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}
